<template>
  <div class="card--project" v-if="listing">
    <!--    <div class="favorite" @click="toggleShortlist">-->
    <!--      <img-->
    <!--        v-if="shortlisted"-->
    <!--        src="@/assets/img/icons/favorite-filled.svg"-->
    <!--        alt="Favorite"-->
    <!--        class="favorite&#45;&#45;filled"-->
    <!--      />-->
    <!--      <img v-else src="@/assets/img/icons/favorite-white.svg" alt="Favorite Outline" />-->
    <!--    </div>-->
    <div class="top--part">
      <card-project-image
        :linkDetail="listing.links ? listing.links.detail : '/'"
        :photos="listing.photos"
        :primaryPhoto="listing.primary_photo"
        :allowMultiImage="allowMultiImage"
        :imageAlt="listing.title"
      ></card-project-image>
    </div>
    <router-link
      :to="listing.links ? listing.links.detail : '/'"
      style="text-decoration: none; width: 100%"
    >
      <div class="bottom--part">
        <h3 class="name multiline-ellipsis-2">
          {{ listing.title }}
        </h3>
        <!--        <div class="size" v-if="listing.land_size || listing.building_size">-->
        <!--          <span class="mr-4" v-if="listing.land_size">LT: {{ listing.land_size }} m2</span>-->
        <!--          <span v-if="listing.building_size">LB: {{ listing.building_size }} m2</span>-->
        <!--        </div>-->
        <div class="location mb-1" v-if="listing.area || listing.city">
          <img src="@/assets/img/icons/pin-1.svg" alt="Location Pin" />
          <div class="multiline-ellipsis-2">
            <span>{{ listing.area }}</span>
            <span v-if="listing.area && listing.city">, </span>
            <span>{{ listing.city }}</span>
          </div>
        </div>
        <div class="price--after">
          <span>{{ $t('general.price') }} </span>
          <span>{{ listing.price_str }}</span>
        </div>
        <div class="label--container">
          <div class="label" v-if="listing.property_type">{{ listing.property_type }}</div>
        </div>
        <room-label :listing="listing" v-if="isSearch"></room-label>
        <div class="fixed--bottom-margin"></div>
        <div class="fixed--bottom" v-if="!isSearch">
          <room-label :listing="listing"></room-label>
          <div class="posted--at" v-if="listing.posted_at">
            <v-icon>mdi-clock-outline</v-icon>
            <span>{{
              `${$date.formatDistanceToNow(this.$date.parse(listing.posted_at))} ${$t(
                'general.ago',
              )}`
            }}</span>
          </div>
        </div>
        <div class="fixed--bottom" v-else>
          <agent-info :listing="listing"></agent-info>
          <div class="posted--at posted--at-fixed" v-if="isSearch && listing.posted_at">
            <v-icon>mdi-clock-outline</v-icon>
            <span>{{
              `${$date.formatDistanceToNow(this.$date.parse(listing.posted_at))} ${$t(
                'general.ago',
              )}`
            }}</span>
          </div>
        </div>
      </div>
    </router-link>
  </div>
</template>

<script>
import CardProjectImage from '@/components/utils/partials/card-project-image';
import RoomLabel from '@/components/utils/partials/room-label';
import AgentInfo from '@/components/utils/partials/agent-info';
import HelperMixin from '@/mixins/helpers';
import { mapState } from 'vuex';

export default {
  mixins: [HelperMixin],
  components: {
    CardProjectImage,
    RoomLabel,
    AgentInfo,
  },
  props: {
    listing: {
      default: null,
      require: true,
    },
    premier: {
      default: false,
    },
    showCompare: {
      default: true,
    },
    allowMultiImage: {
      default: false,
    },
    isSearch: {
      default: false,
    },
  },
  data: () => ({
    debounceCompare: null,
  }),
  computed: {
    ...mapState({
      shortlists: (state) => state.v2.search.shortlists,
      isLoggedIn: (state) => state.global.loggedIn,
    }),
    shortlisted() {
      if (this.shortlists && this.shortlists.length === 0) {
        return false;
      } else {
        return this.shortlists.indexOf(this.listing.uuid) !== -1;
      }
    },
  },
  methods: {
    async toggleShortlist() {
      if (this.isLoggedIn) {
        let response = await this.$store.dispatch('v2/project/addOrRemoveShortlist', {
          listingUuid: this.listing.uuid,
        });
        await this.$store.commit('v2/search/set_shortlists', response);
      } else {
        this.$modal.show('modal-login');
      }
    },
  },
  filters: {
    lowerCase(e) {
      return e.toLowerCase();
    },
  },
};
</script>
