<template>
  <div class="card--project" v-if="project" @mousedown="onMouseDown" @mouseup="onMouseUp">
    <!--    <div class="card&#45;&#45;project-compare" v-show="!premier && showCompare && $mq !== 'xs'">-->
    <!--      <compare :project="project" ref="compare" />-->
    <!--    </div>-->
    <!--    <div v-show="project.subsidized" class="indicator subsidized">-->
    <!--      {{ $t('general.subsidized') }}-->
    <!--    </div>-->
    <!--    <div class="favorite" @click="toggleShortlist">-->
    <!--      <img-->
    <!--        v-if="shortlisted"-->
    <!--        src="@/assets/img/icons/favorite-filled.svg"-->
    <!--        alt="Favorite"-->
    <!--        class="favorite&#45;&#45;filled"-->
    <!--      />-->
    <!--      <img v-else src="@/assets/img/icons/favorite-white.svg" alt="Favorite Outline" />-->
    <!--    </div>-->
    <div class="top--part">
      <card-project-image
        :linkDetail="project.links ? project.links.detail : '/'"
        :photos="project.photos"
        :primaryPhoto="project.primary_photo"
        :allowMultiImage="allowMultiImage"
        :imageAlt="project.title"
      ></card-project-image>
    </div>
    <router-link :to="project.links ? project.links.detail : '/'" style="text-decoration: none">
      <div class="bottom--part">
        <h3 class="name multiline-ellipsis-2">
          {{ project.title }}
        </h3>
        <div class="location mb-1" v-if="project.district || project.city">
          <img src="@/assets/img/icons/pin-1.svg" alt="Location Pin" />
          <div class="multiline-ellipsis-2">
            <span>{{ project.district }}</span>
            <span v-if="project.district && project.city">, </span>
            <span>{{ project.city }}</span>
          </div>
        </div>
        <div class="price--after">
          <span>{{ $t('general.priceStartFrom') }} </span>
          <span>{{ project.min_price_str }}</span>
        </div>
        <div class="label--container">
          <div class="label" v-if="project.property_type">{{ project.property_type }}</div>
        </div>
        <div class="fixed--bottom-margin"></div>
        <div class="fixed--bottom">
          <div class="d-flex">
            <div class="label--room">
              <v-icon>mdi-bed</v-icon>
              <div>{{ compareMinMax(project.min_bedroom, project.max_bedroom) }}</div>
            </div>
            <div class="label--room">
              <v-icon>mdi-shower-head</v-icon>
              <div>{{ compareMinMax(project.min_bathroom, project.max_bathroom) }}</div>
            </div>
          </div>
          <div class="available">
            <span>{{ $t('general.available') }} </span>
            <span>{{ project.total_available_units }} </span>
            <span>{{ $t('general.unit') }} </span>
          </div>
        </div>
      </div>
    </router-link>
  </div>
</template>

<script>
import CardProjectImage from '@/components/utils/partials/card-project-image';
// const Compare = () => import('@/components/utils/partials/compare');
import HelperMixin from '@/mixins/helpers';
import { mapState } from 'vuex';

export default {
  mixins: [HelperMixin],
  components: {
    CardProjectImage,
    // Compare,
  },
  props: {
    project: {
      default: null,
      require: true,
    },
    premier: {
      default: false,
    },
    showCompare: {
      default: true,
    },
    allowMultiImage: {
      default: false,
    },
  },
  data: () => ({
    debounceCompare: null,
  }),
  computed: {
    ...mapState({
      shortlists: (state) => state.v2.search.shortlists,
      isLoggedIn: (state) => state.global.loggedIn,
    }),
    shortlisted() {
      if (this.shortlists && this.shortlists.length === 0) {
        return false;
      } else {
        return this.shortlists.indexOf(this.project.uuid) !== -1;
      }
    },
  },
  methods: {
    async toggleShortlist() {
      if (this.isLoggedIn) {
        let response = await this.$store.dispatch('v2/project/addOrRemoveShortlist', {
          projectUuid: this.project.uuid,
        });
        await this.$store.commit('v2/search/set_shortlists', response);
      } else {
        this.$modal.show('modal-login');
      }
    },
    onMouseDown() {
      // Hold down to add project to compare still not working
      // if (this.$mq === 'xs') {
      //   this.debounceCompare = this.debounce(this.addToCompare, 50);
      // }
    },
    onMouseUp() {
      // Hold down to add project to compare still not working
      // if (this.$mq === 'xs') {
      //   this.debounceCompare = null;
      // }
    },
    addToCompare(project) {
      this.$store.commit('v2/project/set_compare_project', project);
      this.$store.commit('v2/project/set_compare_show', true);
    },
  },
  filters: {
    lowerCase(e) {
      return e.toLowerCase();
    },
  },
};
</script>
