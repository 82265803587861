<template>
  <section class="search--header">
    <search-profile
      class="hide--in-tablet hide--in-desktop"
      v-if="$route.name === 'search-user-listing' || $route.name === 'search-agent-catalog'"
    ></search-profile>
    <!--    <search-project :forceSearch="forceSearch" v-else />-->
    <div class="multiselect--region-search" v-else>
      <multiselect-region @forceSearch="forceSearch"></multiselect-region>
      <div class="search--button" @click="forceSearch">
        <img src="@/assets/img/icons/search.svg" alt="Cari" />
        <span>{{ $t('general.search') }}</span>
      </div>
    </div>
    <div class="search--header-content">
      <div class="listing--type-tab mr-1">
        <v-tabs
          center-active
          show-arrows
          v-model="activeListingTypeTab"
          v-if="listingTypes && listingTypes.length > 0"
        >
          <v-tab
            :href="`#tab-listing-type-${listingType.id}`"
            v-for="listingType in listingTypes"
            :key="`listing-type-${listingType.id}`"
            @click="inputListingTypeHandler(listingType)"
          >
            <div class="d-flex justify-center">
              <img src="@/assets/img/icons/paper.png" v-if="listingType.id === 1" />
              <img src="@/assets/img/icons/tag.png" v-else />
              {{ listingType.name }}
            </div>
          </v-tab>
        </v-tabs>
      </div>
      <!--      <v-select-->
      <!--        v-model="selectedPropertyType"-->
      <!--        :items="propertyTypes"-->
      <!--        :label="$t('general.filter.propertyType')"-->
      <!--        item-value="id"-->
      <!--        item-text="name"-->
      <!--        solo-->
      <!--        hide-details-->
      <!--        return-object-->
      <!--        class="mr-1"-->
      <!--        v-if="$mq !== 'xs'"-->
      <!--      ></v-select>-->
      <!--      <v-select-->
      <!--        v-model="minBedroom"-->
      <!--        :items="minBedrooms"-->
      <!--        :label="$t('general.filter.minBedroom')"-->
      <!--        item-value="id"-->
      <!--        item-text="name"-->
      <!--        solo-->
      <!--        hide-details-->
      <!--        return-object-->
      <!--        class="mr-1"-->
      <!--        v-if="$mq !== 'xs'"-->
      <!--      >-->
      <!--        <template v-slot:selection="{ item, index }">-->
      <!--          {{ item.name }}-->
      <!--        </template>-->
      <!--      </v-select>-->
      <!--      <v-menu-->
      <!--        ref="priceMenu"-->
      <!--        content-class="filter&#45;&#45;menu"-->
      <!--        :close-on-content-click="false"-->
      <!--        v-if="$mq !== 'xs'"-->
      <!--      >-->
      <!--        <template v-slot:activator="{ on, attrs }">-->
      <!--          <div class="filter&#45;&#45;menu-button" v-bind="attrs" v-on="on">-->
      <!--            <div :class="{ 'filter&#45;&#45;menu-filled': sellPriceMin || sellPriceMax }">-->
      <!--              {{ priceFilterText }}-->
      <!--            </div>-->
      <!--            <v-icon right>mdi-menu-down</v-icon>-->
      <!--          </div>-->
      <!--        </template>-->
      <!--        <div>-->
      <!--          <div class="menu&#45;&#45;title mb-4">-->
      <!--            {{ $t('general.filter.price') }}-->
      <!--          </div>-->
      <!--          <div>-->
      <!--            <cleave-->
      <!--              v-model="tempSellPriceMin"-->
      <!--              :placeholder="$t('general.min')"-->
      <!--              :options="cleaveOptionsPrice"-->
      <!--              class="basic&#45;&#45;input mb-2"-->
      <!--              name="sellPriceMin"-->
      <!--            ></cleave>-->
      <!--          </div>-->
      <!--          <div>-->
      <!--            <cleave-->
      <!--              v-model="tempSellPriceMax"-->
      <!--              :placeholder="$t('general.max')"-->
      <!--              :options="cleaveOptionsPrice"-->
      <!--              class="basic&#45;&#45;input mb-4"-->
      <!--              name="sellPriceMax"-->
      <!--            ></cleave>-->
      <!--          </div>-->
      <!--          <div class="clear&#45;&#45;price-filter" @click="clearPriceFilter">-->
      <!--            <v-icon class="mr-2">mdi-delete</v-icon>-->
      <!--            {{ $t('general.filter.removePriceFilter') }}-->
      <!--          </div>-->
      <!--          <button class="btn btn-primary" type="button" @click="setSellPrice">-->
      <!--            {{ $t('general.filter.apply') }}-->
      <!--          </button>-->
      <!--        </div>-->
      <!--      </v-menu>-->
    </div>
  </section>
</template>

<script>
// import SearchProject from '@/components/search/partials/search-project';
import SearchProfile from '@/components/search/search-profile';
const MultiselectRegion = () => import('@/components/utils/partials/multiselect-region');
import { mapGetters, mapState } from 'vuex';
import HelperMixin from '@/mixins/helpers';

export default {
  name: 'search-header',
  mixins: [HelperMixin],
  components: {
    // SearchProject,
    SearchProfile,
    MultiselectRegion,
  },
  data: () => ({
    activeListingTypeTab: 0,
    cleaveOptionsPrice: {
      numeral: true,
      numeralDecimalMark: ',',
      delimiter: '.',
      prefix: 'Rp ',
      noImmediatePrefix: true,
    },
  }),
  computed: {
    ...mapState({
      listingTypes: (state) => state.v2.masters.listingTypes,
      minBedrooms: (state) => state.v2.search.minBedrooms,
    }),
    ...mapGetters({
      propertyTypes: 'v2/masters/propertyTypesForFilter',
    }),
    selectedListingType: {
      get() {
        return this.$store.state.v2.search.selectedListingType;
      },
      async set(value) {
        await this.$store.dispatch('v2/search/setListingType', {
          payload: value,
          query: this.$route.query,
        });
        await this.forceSearch();
      },
    },
    selectedPropertyType: {
      get() {
        return this.$store.state.v2.search.selectedPropertyType;
      },
      async set(value) {
        await this.$store.dispatch('v2/search/setPropertyType', {
          payload: value,
          query: this.$route.query,
        });
        await this.forceSearch();
      },
    },
    minBedroom: {
      get() {
        return this.$store.state.v2.search.minBedroom;
      },
      set(value) {
        this.$store.commit('v2/search/SET_MIN_BEDROOM', value);
        this.$store.dispatch('v2/search/goToSearchPage', {
          router: this.$router,
          params: this.$route.params,
        });
      },
    },
    tempSellPriceMin: {
      get() {
        return this.$store.state.v2.search.tempSellPriceMin;
      },
      set(value) {
        this.$store.commit('v2/search/SET_TEMP_SELL_PRICE_MIN', value);
      },
    },
    tempSellPriceMax: {
      get() {
        return this.$store.state.v2.search.tempSellPriceMax;
      },
      set(value) {
        this.$store.commit('v2/search/SET_TEMP_SELL_PRICE_MAX', value);
      },
    },
    sellPriceMin: {
      get() {
        return this.$store.state.v2.search.sellPriceMin;
      },
      set(value) {
        this.$store.commit('v2/search/SET_SELL_PRICE_MIN', value);
      },
    },
    sellPriceMax: {
      get() {
        return this.$store.state.v2.search.sellPriceMax;
      },
      set(value) {
        this.$store.commit('v2/search/SET_SELL_PRICE_MAX', value);
      },
    },
    priceFilterText() {
      if (this.sellPriceMin || this.sellPriceMax) {
        let text = '';
        if (this.sellPriceMin) text += this.sellPriceMin;
        if (this.sellPriceMin && this.sellPriceMax) text += '-';
        if (this.sellPriceMax) text += this.sellPriceMax;
        if (this.$mq !== 'xs') {
          if (text.length > 20) text = text.substring(0, 18) + '...';
        } else {
          if (text.length > 20) text = text.substring(0, 18) + '...';
        }
        return text;
      } else {
        return this.$t('general.filter.price');
      }
    },
  },
  watch: {
    async selectedListingType(value) {
      this.calculateListingTypeTab(value.id);
    },
  },
  mounted() {
    this.calculateListingTypeTab(this.selectedListingType.id);
  },
  methods: {
    clearPriceFilter() {
      this.tempSellPriceMin = null;
      this.tempSellPriceMax = null;
    },
    setSellPrice() {
      this.sellPriceMin = this.tempSellPriceMin;
      this.sellPriceMax = this.tempSellPriceMax;
      this.$refs.priceMenu.save();
      this.$store.dispatch('v2/search/goToSearchPage', {
        router: this.$router,
        params: this.$route.params,
      });
    },
    inputListingTypeHandler(value) {
      this.selectedListingType = value;
    },
    calculateListingTypeTab(id) {
      this.activeListingTypeTab = 'tab-listing-type-' + id;
    },
    async forceSearch() {
      this.$store
        .dispatch('v2/search/goToSearchPage', {
          router: this.$router,
          params: this.$route.params,
        })
        // Catch error route karena routing ke url yang sama
        .catch(async () => {
          console.log('SAME ROUTE, FORCING SEARCH!');
          this.$store.commit('global/SET_LOADING', true);
          await this.$store.dispatch('v2/search/getSearchProject', {
            query: this.$route.query,
            params: this.$route.params,
          });
          this.$store.commit('global/SET_LOADING', false);
        });
    },
  },
};
</script>
