<template>
  <div class="card--project-image">
    <router-link
      :to="linkDetail"
      style="text-decoration: none"
      v-if="useRouterLink && !useMultiImage"
    >
      <picture>
        <source :srcset="photoUrl" type="image/webp" />
        <source :srcset="getFallbackImage(photoUrl)" type="image/jpeg" />
        <img :src="getFallbackImage(photoUrl)" class="project--image" :alt="newImageAlt" />
      </picture>
    </router-link>
    <div v-else-if="!useRouterLink">
      <picture>
        <source :srcset="photoUrl" type="image/webp" />
        <source :srcset="getFallbackImage(photoUrl)" type="image/jpeg" />
        <img :src="getFallbackImage(photoUrl)" class="project--image" :alt="newImageAlt" />
      </picture>
    </div>
    <v-carousel class="carousel--wrapper" v-else>
      <v-carousel-item v-for="(photo, index) in photos" :key="index" class="carousel--slide">
        <router-link :to="linkDetail" style="text-decoration: none">
          <picture>
            <source :srcset="forceHDImage(photo.url)" type="image/webp" />
            <source :srcset="getFallbackImage(forceHDImage(photo.url))" type="image/jpeg" />
            <img
              :src="getFallbackImage(forceHDImage(photo.url))"
              class="project--image"
              :alt="newImageAlt"
            />
          </picture>
        </router-link>
      </v-carousel-item>
    </v-carousel>
  </div>
</template>

<script>
import HelperMixin from '@/mixins/helpers';

export default {
  mixins: [HelperMixin],
  props: {
    linkDetail: {
      default: '/',
      require: true,
    },
    primaryPhoto: {
      default: null,
      require: true,
    },
    photos: {
      default: null,
      require: true,
    },
    allowMultiImage: {
      default: false,
    },
    useRouterLink: {
      default: true,
    },
    imageAlt: {
      default: null,
      require: true,
    },
  },
  data: () => ({}),
  computed: {
    useMultiImage() {
      // return this.photos && this.photos.length > 1 && this.allowMultiImage;
      return false;
    },
    newImageAlt() {
      return this.imageAlt + ' - BeliRumah';
    },
    photoUrl() {
      let url = this.primaryPhoto ? this.primaryPhoto.url : '';
      return this.forceHDImage(url);
    },
  },
  methods: {},
};
</script>
